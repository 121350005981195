$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova-Black', sans-serif;
$secondary-font--bold: 'ProximaNova-Bold', sans-serif;
$secondary-font--extrabold: 'ProximaNova-ExtraBold', sans-serif;

$white:#FFFFFF;
$black: #000000;
$yellow: #EEBD2B;
$header-black: #282626;
$sub-title-yellow: #EBEBD3;
$slider-blue: #32A7F5;
$sub-title-gray: #65656A;
$link-blue: #89F6FB;
$footer-gray: #909095;