@import 'styles/variables';
@import 'styles/extensions';

.main-title {
  &__wrapper {
    @extend %wrapper;
    padding-top: 180px;
    padding-bottom: 50px;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
      padding-top: calc(200px * #{$zoom});
      padding-bottom: calc(50px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      padding-top: 180px;
    }

    @media screen and (max-width: 475px) {
      padding-top: 100px;
    }
  }

  h1 {
    position: relative;
    z-index: 100;
    padding-bottom: 40px;
    color: $yellow;
    font-weight: normal;
    font-size: 96px;
    font-family: $secondary-font;
    line-height: 104px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    span {      
      @media screen and (max-width: 450px) {
        font-size: 8.7vw;
        line-height: 8.7vw;
      }

      > span {
        color: $yellow;
      }
    }

    @media (min-width: 2560px) {
      font-size: calc(96px * #{$zoom});
      line-height: calc(104px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      font-size: 7vw;
      line-height: 7.5vw;
    }

    @media screen and (max-width: 768px) {
      font-size: 10vw;
      line-height: 10.5vw;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 11vw;
      line-height: 1;
    }
  }

  &__heading {
    position: relative;
    z-index: 0;
    display: flex;
    gap: 55px;
    justify-content: flex-start;
    height: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
  
  &__description {
    position: relative;
    flex-basis: 60%;
    max-width: 680px;
    margin-bottom: 100px;
    color: $white;
    font-weight: 400;
    font-size: 20px;
    font-style: normal;
    line-height: 32px; 
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
      font-size: calc(20px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 24px;
    }

    &_colored-text {
      display: inline-block;
      margin-bottom: 50px;
      color: $sub-title-yellow;
      font-weight: 800;
      font-size: 32px;
      font-family: $secondary-font--extrabold;
      line-height: 36px;

      @media (min-width: 2560px) {
        max-width: calc(600px * #{$zoom});
        font-size: calc(32px * #{$zoom});
        line-height: calc(36px * #{$zoom});
      }
  
      @media screen and (max-width: 450px) {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &__inview {
    position: relative;
    flex-basis: 45%;

    & > div {
      position: sticky;
      top: 58%;

      @media screen and (max-width: 768px) {
        position: relative;
        z-index: -1;
        width: 100%;
        height: 100%;
      }
    }

    img, video {
      position: relative;
      top: 30%;
      left: 60%;
      z-index: -1;
      width: 100%;
      margin-top: 10%;
      transform: translate(-50%, -50%) scale(2.5);
      filter: drop-shadow(0px 4px 2px black);
      pointer-events: none;

      @media screen and (min-width: 768px) and (max-height: 890px) {
        margin-top: 15%;
      }

      @media screen and (max-height: 768px) and (max-width: 1640px) and (min-width:1024px) {
        margin-top: 20vh;
      }

      @media screen and (max-width: 768px) {
        left: -20%;
        width: 140%;
        transform: scale(1);
      }

      @media screen and (max-width: 480px) {
        left: -50%;
        width: 200%;
      }
    }
  }

  &__hero-image {
    width: 100%;
  }

  &__body-logo {
    position: absolute;
    top: 0;
    right: 5%;
    z-index: 1;
    max-width: 315px;

    @media screen and (min-width: 2560px) {
      right: 10%;
      max-width: calc(351px * #{$zoom});
    }

    @media screen and (max-width: 1024px) and (min-width: 769px) {
      right: 0;
      max-width: 34vw;
    }

    @media screen and (max-width: 768px) {
      top: 0px;
      left: 29%;
      max-width: 263px;
    }

    @media screen and (max-width: 540px) {
      right: 20%;
      left: unset;
    }

    @media screen and (max-width: 475px) {
      top: 35px;
      left: 28vw;
      max-width: 167.5px;
      object-fit: contain;
    }

    @media screen and (max-width: 390px) {
      top: 2vw;
    }
  }

  &__items {
    padding-top: 100px;

    @media (max-width: 768px) {
      padding-top: 50px;
    }
    
    @media (max-width: 475px) {
      display: flex;
      column-gap: 20px;
    }
  }

  &__item-icons {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    column-gap: 40px;

    @media (max-width: 475px) {
      flex-direction: column;
      row-gap: 30px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: -200%;
      z-index: 0;
      width: 0;
      height: 36px;
      background-image: repeating-linear-gradient(-45deg, $yellow 0, $yellow 4px, transparent 4px, transparent 8px);
      transform: translateY(-50%);
      transition: all 1.5s ease;
      content: '';
    }

    &.add-line {
      &::before {        
        left: -50%;
        width: 200vw;
        transition: all 1.5s ease;

        @media (max-width: 475px) {
          left: 30%;
          width: 15px;
          height: 100%;
        }
      }
    }

    & > div {
      position: relative;
      z-index: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      max-width: 83px;

      @media (min-width: 2560px) {
        max-width: calc(100px * #{$zoom});
      }  

      @media (max-width: 475px) {
        min-width: 42px;
      }
    }
  }

  &__item-text {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    color: $white;
    column-gap: 40px;

    @media (max-width: 475px) {
      flex-direction: column;
      row-gap: 30px;
      margin-top: 0;
    }

    & > div {
      position: relative;
      z-index: 0;
      flex: 1;
    }

    h3 {
      font-size: 24px;
      font-family: $secondary-font;
      font-style: normal;
      line-height: 125%;

      @media (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
      }  
    }

    p {
      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
      }
    }
  }
}