@import 'styles/variables';
@import 'styles/extensions';

.how-it-works {
  &__wrapper {
    @extend %wrapper;
    position: relative;
    padding-top: 100px;
    padding-bottom: 150px;

    &::before {
      position: absolute;
      top: 30%;
      left: 65%;
      z-index: -1;
      width: 100%;
      max-width: 500px;
      height: 100%;
      max-height: 1166px;
      background-image: url(../../../../../asset/resource/technology-bg-image.png);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: 100%;
      content: '';

      @media (min-width: 2560px) {
        top: 25%;
        max-width: calc(500px * #{$zoom});
        max-height: calc(1166px * #{$zoom});
      }
    }
  }

  h2 {
    @extend %wrapper;
    max-width: 75%;
    margin: 0 0 30px 0;
    padding-right: 0;
    padding-left: 0;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font--extrabold;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(75% * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $yellow;
    }
  }

  &__description {
    max-width: 65%;
    padding-bottom: 90px;
    color: $white;
    font-size: 20px;

    @media (min-width: 2560px) {
      max-width: calc(75% * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(20px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
    }
  }


  &__blocks {
    margin: 0 auto;

    & > div:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__card {
    position: relative;
    max-width: 480px;
    padding: 32px 0;
    color: $white;
    background-color: $header-black;

    @media (min-width: 2560px) {
      max-width: calc(480px * #{$zoom});
      font-size: calc(16px * 1.44);
    }

    &::before {
      position: absolute;
      top: 50%;
      left: -200%;
      z-index: -1;
      width: 200%;
      height: 36px;
      background-image: repeating-linear-gradient(-45deg, $yellow 0, $yellow 4px, transparent 4px, transparent 8px);
      transform: translateY(-50%);
      content: '';
    }

    figure {
      padding-bottom: 24px;

      img {
        object-fit: contain;
      }
    }

    p, ol {
      padding: 0 32px;

      @media (max-width: 475px) {
        padding: 0 20px;
      }
    }

    ol {
      margin-bottom: 40px;
      padding-left: 52px;
      font-weight: 700;
      font-size: 20px;
      font-family: $basic-font;
      line-height: 1.4;

      @media screen and (min-width: 2560px) {
        margin-bottom: calc(40px * #{$zoom});
        padding-left: calc(52px * #{$zoom});
        font-size: calc(20px * #{$zoom});
      }
    }
  }
}