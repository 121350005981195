@import 'styles/variables';
@import 'styles/extensions';
@import 'styles/mixins';

.try-it-now {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 100px;
    color: $white;

    @media screen and (max-width: 1024px) {
        padding: 60px 40px;
    }

    @media screen and (max-width: 480px) {
        padding: 60px 20px;
    }
    

    @media screen and (min-width: 2560px) {
        padding: calc(100px * #{$zoom}) calc(100px * #{$zoom});
    }

    &__content {
        width: 100%;
        max-width: 1240px;

        @media screen and (min-width: 2560px) {
            max-width: calc(1240px * #{$zoom});
        }
    }

    &__name {
        @include section-name(#F1F1F4);
        @include line-above-text(238px, #F1F1F4)
    }

    &__title {
        @include section-heading(84px);
        color: $yellow;
        text-align: center;

        @media screen and (max-width: 1024px) {
            text-align: left;
        }

        @media screen and (max-width: 480px) {
            padding-top: 24px;
          }

        @media screen and (min-width: 2560px) {
            padding-top: calc(56px * #{$zoom});
        }
    }

    &__items {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        padding-bottom: 128px;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            padding-top: 60px;
            padding-bottom: 88px;
        }

        @media screen and (max-width: 640px) {
            padding-bottom: 0px;
            overflow: visible;
        }

        @media screen and (min-width: 2560px) {
            padding-top: calc(100px * #{$zoom});
            padding-bottom: calc(128px * #{$zoom});
        }

        &::before {
            position: absolute;
            left: calc(50% - 18px);
            content: "";
            width: 36px;
            height: 100%;
            background-image: repeating-linear-gradient(-45deg, $yellow 0, $yellow 4px, transparent 4px, transparent 8px);
            background-repeat: repeat;
            background-size: 100%;

            @media screen and (max-width: 1024px) {
                left: 55px;
            }

            @media screen and (max-width: 640px) {
                left: 14px;
                width: 18px;
                height: 95%;
            }

            @media screen and (max-width: 480px) {
                left: 30px;
                height: 97%;
             }
        }
    }

    &__item-wrapper {
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-top: 100px;
        
        &:nth-of-type(2n) {
            justify-content: flex-end;
        }

        @media screen and (max-width: 640px) {
            padding-top: 50px;
        }
    }

    &__red-line {
        position: absolute;
        width: 125px;
        height: 1px;
        background-color: $header-black;

        @media screen and (max-width: 1024px) {
            display: none;
        }

        &_left {
            left: -125px;
        }
        
        &_right {
            right: -125px;
        }
    }

    &__item {
        position: relative;
        z-index: 4;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 492px;
        padding: 20px;
        background-image: url(../../../../../asset/resource/concrete-bg-image.png);
        border: 1px solid $header-black;

        @media screen and (max-width: 1440px) {
            max-width: 30vw;
        }

        @media screen and (max-width: 1024px) {
            max-width: 100%;
            margin-left: calc(150px + 12px);
            border: none;
            transform: none !important;
        }

        @media screen and (max-width: 640px) {
            margin-left: 100px;
            padding: 0px;
        }

        @media screen and (min-width: 2560px) {
            gap: calc(10px * #{$zoom});
            max-width: calc(492px * #{$zoom});
            padding: calc(20px * #{$zoom});
        }
    }

    &__item_left {
        @include line-to-circle(right);
    }

    &__item_right {
        @include line-to-circle(left);
    }

    &__item-number {
        color: #D1D1D1;
        font-weight: 800;
        font-size: 20px;
        font-family: $secondary-font;

        @media screen and (min-width: 2560px) {
            font-size: calc(20px * #{$zoom});
        }
    }

    &__item-title {
        color: $white;
        font-weight: 800;
        font-size: 20px;
        font-family: $secondary-font;

        @media screen and (min-width: 2560px) {
            font-size: calc(20px * #{$zoom});
        }
    }

    &__item-description {
        font-size: 16px;
        font-family: $basic-font;

        @media screen and (min-width: 2560px) {
            font-size: calc(16px * #{$zoom});
        }

        ul {
            padding-left: 20px;
            list-style: disc;
        }
    }

    &__icon-wrapper {
        position: absolute;
        top: 45%;
        left: calc(50% - 72px);
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        aspect-ratio: 1;

        @media screen and (max-width: 1024px) {
            left: 0;
        }

        @media screen and (max-width: 640px) {
            top: 50px;
            left: -17px;
            width: 80px;
        }

        @media screen and (max-width: 480px) {
            top: 50px;
            left: 0px;
            width: 80px;
        }

        @media screen and (min-width: 2560px) {
            top: 40%;
            left: calc(50% - calc((150px * #{$zoom}) / 2 - 6px));
            width: calc(150px * #{$zoom});
        }

        img {
            width: 151px;

            @media screen and (max-width: 640px) {
                width: 60px;
            }

            @media screen and (min-width: 2560px) {
                transform: scale(1.44);
            }
        }
    }
}

